import * as bootstrap from 'bootstrap'
import './styles.scss'

// Старый js и css перенесенный в сборщик
import dayjs from '@/helpers/dayjs'
import toasts from '@/helpers/toasts'

// Экспорты библиотек
window.bootstrap = bootstrap
window.dayjs = dayjs

// Экспорты легаси-функционала
window.ToastGen = toasts.gen
window.ToastError = toasts.error
window.ToastSuccess = toasts.success
window.ToastInfo = toasts.info
window.ToastWarning = toasts.warning
